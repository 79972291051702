<template>
  <div class="animated fadeIn">
    <b-row class="justify-content-md-center">
      <b-col sm="8">
        <b-form @submit.stop.prevent="onSubmit" @reset="onReset" v-if="show">
          <b-card header-tag="header" footer-tag="footer">
            <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
              :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor"
              :z-index="10000"></loading>
            <h5 slot="header" class="mb-0">
              <b-button size="sm" :to="{ name: 'system.users.list' }" class="mr-2"><i class="fa fa-chevron-left"></i>
                Буцах</b-button>
              <span v-if="this.$route.params.id > 0">Засах: <strong class="text-primary">{{ form.lastname }} {{
          form.firstname }}</strong></span>
              <span v-else>Шинээр нэмэх</span>
            </h5>

            <b-form-group id="input-group-lastname" label="Овог:" label-for="input-lastname">
              <b-form-input id="input-lastname" v-model="form.lastname" type="text" placeholder="Овог"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-firstname" label="Нэр:" label-for="input-firstname">
              <b-form-input id="input-firstname" v-model="form.firstname" type="text" required
                placeholder="Нэр"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-email" label="И-мэйл:" label-for="input-email">
              <b-form-input id="input-email" v-validate="'email|required'" v-model="form.emailAddress" type="email"
                required placeholder="И-мэйл"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-password" label="Нууц үг:" label-for="input-password">
              <b-form-input id="input-password" v-model="form.password" type="password"
                placeholder="Нууц үг"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-password_confirmation" label="Нууц үг давт:"
              label-for="input-password_confirmation">
              <b-form-input id="input-password_confirmation" v-model="form.password_confirmation" type="password"
                placeholder="Нууц үг давт"></b-form-input>
              <b-form-invalid-feedback :state="validation">
                Нууц үг таарахгүй байна
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="input-group-4">
              <b-form-checkbox value="true" v-model="form.isActive" switch>Идэвхтэй</b-form-checkbox>
            </b-form-group>

            <b-form-group id="input-group-roleObj" label="Хандах эрх:" label-for="input-roleObj">
              <multiselect v-model="form.roleObj" :options="roles" placeholder="Хандах эрх" label="name" track-by="id"
                :multiple="false"></multiselect>
            </b-form-group>

            <div slot="footer">
              <b-button type="submit" variant="primary">Хадгалах</b-button>
              <b-button type="reset" variant="danger" class="ml-3">Шинэчлэх</b-button>
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>


export default {
  name: 'Users.edit',
  data: function () {
    return {
      isLoading: false,
      form: {
        lastname: '',
        firstname: '',
        email: '',
        password: '',
        password_confirmation: '',
        isActive: false,
        roleObj: null
      },
      show: true,
      roles: [],
      save_url: 'AdminWebService/create_sys_user'
    }
  },
  created: function () {
    this.checkSession();
    this.getRoles()
    if (this.$route.params.id > 0) {
      this.$data.save_url = 'AdminWebService/update_sys_user'
      this.loadData()
    }
  },
  computed: {
    validation() {
      return this.$data.form.password == this.$data.form.password_confirmation || this.$data.form.password_confirmation == ''
    }
  },
  methods: {
    getRoles: function () {
      this.$http.get(this.$config.API_URL + 'UserWebService/get_roles',
        {
          params: {
            page: 1,
            per_page: 100,
            orderBy: 'name',
            orderDirection: 'ASC',
            search: ''
          },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true
        })
        .then(response => {
          this.$data.roles = response.body.data
        });
    },
    loadData: function () {
      this.isLoading = true;
      this.$http.post(this.$config.API_URL + 'AdminWebService/get_sys_user', {
        data: JSON.stringify({
          'token': this.$store.getters.token,
          'email': this.$store.getters.email,
          id: this.$route.params.id
        })
      },
        {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true
        })
        .then(response => {
          this.isLoading = false;
          this.$data.form = response.body
        }, response => {
          this.isLoading = false;
          this.showToast('Алдаа', 'Алдаа гарсан байна', 'danger')
        });
    },
    onSubmit(evt) {
      let valid = true
      if (this.$data.form.id == null) {
        if (this.$data.form.password != '' && this.$data.form.password.length < 6) {
          this.showToast('Анхаар', 'Нууц үг 6 урттай байх хэсэгтэй', 'warning')
          valid = false;
        } else if (this.$data.form.password != '' && this.$data.form.password != this.$data.form.password_confirmation) {
          this.showToast('Анхаар', 'Нууц үг таарахгүй байна', 'warning')
          valid = false
        }
      } else {
        if (this.$data.form.password != '') {
          if (this.$data.form.password != '' && this.$data.form.password.length < 6) {
            this.showToast('Анхаар', 'Нууц үг 6 урттай байх хэсэгтэй', 'warning')
            valid = false;
          } else if (this.$data.form.password != '' && this.$data.form.password != this.$data.form.password_confirmation) {
            this.showToast('Анхаар', 'Нууц үг таарахгүй байна', 'warning')
            valid = false
          }
        }
      }
      if (valid) {
        this.isLoading = true
        this.$data.form.token = this.$store.getters.token
        this.$data.form.email = this.$store.getters.email

        this.$http.post(
          this.$config.API_URL + this.$data.save_url,
          { data: JSON.stringify(this.$data.form) },
          { headers: this.$store.getters.httpHeader, emulateJSON: true }
        ).then(
          response => {
            this.isLoading = false;
            this.showToast('Амжилттай', 'Амжилттай хадгалагдлаа', 'success')
            if (this.$route.params.id == 0) {
              this.onReset(evt)
            }
          },
          response => {
            this.isLoading = false;
            this.showToast('Алдаа', 'Алдаа гарсан байна', 'danger')
          }
        );
      }
    },
    onReset(evt) {
      evt.preventDefault()
      this.$data.form = {
        name: '',
        code: '',
        icon: '',
        action: '',
        isActive: false,
        displayIndex: '',
        roles: ''
      }
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
</script>